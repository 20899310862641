import { combineReducers } from 'redux';
import searchReducer from './searchReducer';
import { reducer as formReducer } from 'redux-form';
import menuReducer from './menuReducer';
import companyReducer from './companyReducer';
import userAccountReducer from './userAccountReducer';
import loginSignupReducer from './loginSignupReducer';
import directorReducer from './directorReducer';
import commonReducer from './commonReducer';
import popularCompaniesReducer from './popularCompaniesReducer';
import subscriptionReducer from './subscriptionReducer';
import blogReducer from './blogReducer';
import productReducer from './productReducer';
import industryProfileReducer from './industryProfileReducer';
import locationProfileReducer from './locationProfileReducer';
import businessPlanTemplateReducer from './businessPlanTemplateReducer';
import businessPlanReducer from './businessPlanReducer';
import envVariableReducer from './envVariableReducer'
import competitiveAnalysisReducer from './competitorAnalysisReducer';
import industrySicCodesReducer from './industrySicCodesReducer'

export default combineReducers({
    form: formReducer,
    searchReducer,
    menu: menuReducer,
    company: companyReducer,
    userProfile: userAccountReducer,
    loginSignup: loginSignupReducer,
    director: directorReducer,
    popularCompanies: popularCompaniesReducer,
    common: commonReducer,
    subscription: subscriptionReducer,
    blog: blogReducer,
    product: productReducer,
    industryProfile: industryProfileReducer,
    locationProfile: locationProfileReducer,
    businessPlanTemplate: businessPlanTemplateReducer,
    businessPlan: businessPlanReducer,
    envVarLoad: envVariableReducer,
    competitiveAnalysis: competitiveAnalysisReducer,
    industrySicCodes: industrySicCodesReducer
})

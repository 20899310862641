import { ADD_COMPANY_TO_WATCHLIST_FAILED, ADD_COMPANY_TO_WATCHLIST_REQUEST, 
    CREATE_WATCHLIST_FAILED, CREATE_WATCHLIST_REQUEST,  DELETE_WATCHLIST_FAILED,
    DELETE_WATCHLIST_REQUEST, GET_UPDATED_WATCHLIST_AFTER_SUCCESS, GET_WATCHLIST_FAILED, GET_WATCHLIST_REQUEST, REMOVE_COMPANY_FROM_WATCHLIST_FAILED, 
    REMOVE_COMPANY_FROM_WATCHLIST_REQUEST, REMOVE_COMPANY_FROM_WATCHLIST_SUCCESS, UPDATE_WATCHLIST_FAILED,
    UPDATE_WATCHLIST_REQUEST,UPLOAD_CSV_TO_WATCHLIST_REQUEST,UPLOAD_CSV_TO_WATCHLIST_SUCCESS,COMPANY_WATCHLIST_INFO_REQUEST,COMPANY_WATCHLIST_INFO_SUCCESS, TOGGLE_ADD_EDIT_CP_WATCHLIST_MODAL, TOGGLE_ADD_TO_WATCHLIST_MODAL, TOGGLE_CREATE_WATCHLIST_MODAL, UPLOAD_CSV_TO_WATCHLIST_FAILED,
    TOGGLE_UPLOAD_CSV_TO_WATCHLIST_MODAL, } from "./actionConstants"

export const createWatchListRequest = (watchListObj) => ({
    type: CREATE_WATCHLIST_REQUEST,
    payload: { watchListObj }
})

export const getUpdateWatchListAfterSuccess = (watchList) => ({
    type: GET_UPDATED_WATCHLIST_AFTER_SUCCESS,
    payload: { watchList }
})

export const createWatchListFailed = (errorMessage) => ({
    type: CREATE_WATCHLIST_FAILED,
    payload: { errorMessage }
})

export const updateWatchListRequest = (updateObj) => ({
    type: UPDATE_WATCHLIST_REQUEST,
    payload: { updateObj }
})

export const updateWatchListFailed = (errorMessage) => ({
    type: UPDATE_WATCHLIST_FAILED,
    payload: { errorMessage }
})

export const deleteWatchListRequest = (watchListId) => ({
    type: DELETE_WATCHLIST_REQUEST,
    payload: { watchListId }
})

export const deleteWatchListFailed = (errorMessage) => ({
    type: DELETE_WATCHLIST_FAILED,
    payload: { errorMessage }
})

export const getWatchListRequest = (userId) => ({
    type: GET_WATCHLIST_REQUEST,
    payload: { userId }
})

export const getWatchListFailed = (errorMessage) => ({
    type: GET_WATCHLIST_FAILED,
    payload: { errorMessage }
})

export const addCompanyToWatchListRequest = (watchListObj) => ({
    type: ADD_COMPANY_TO_WATCHLIST_REQUEST,
    payload: { watchListObj }
})

export const addCompanyToWatchListFailed = (errorMessage) => ({
    type: ADD_COMPANY_TO_WATCHLIST_FAILED,
    payload: { errorMessage }
})

export const removeCompanyFromWatchListRequest = (companyObj) => ({
    type: REMOVE_COMPANY_FROM_WATCHLIST_REQUEST,
    payload: { companyObj }
})

export const removeCompanyFromWatchListSuccess = (message) => ({
    type: REMOVE_COMPANY_FROM_WATCHLIST_SUCCESS,
    payload: { message }
})

export const removeCompanyFromWatchListFailed = (errorMessage) => ({
    type: REMOVE_COMPANY_FROM_WATCHLIST_FAILED,
    payload: { errorMessage }
})

export const setCreateWatchListModalToggleStatus = (toggleStatus) => ({
    type: TOGGLE_CREATE_WATCHLIST_MODAL,
    payload: { toggleStatus }
})

export const setAddWatchListModalToggleStatus = (toggleStatus) => ({
    type: TOGGLE_ADD_TO_WATCHLIST_MODAL,
    payload: { toggleStatus }
})

export const setUploadCsvToWatchListModalToggleStatus = (toggleStatus) => ({
    type: TOGGLE_UPLOAD_CSV_TO_WATCHLIST_MODAL,
    payload: {toggleStatus}
})

export const findCompanyInWatchListRequest = (companyObj) => ({
    type: COMPANY_WATCHLIST_INFO_REQUEST,
    payload: { companyObj }
})

export const findCompanyInWatchListSuccess = (watchlist) => ({
    type: COMPANY_WATCHLIST_INFO_SUCCESS,
    payload: { watchlist }
})

export const addEditCpToWatchListModal = (modalStatus) => ({
    type: TOGGLE_ADD_EDIT_CP_WATCHLIST_MODAL,
    payload: { modalStatus }
})
export const uploadCsvToWatchListRequest = (formData) => ({
    type: UPLOAD_CSV_TO_WATCHLIST_REQUEST,
    payload: { formData }
})

export const uploadCsvToWatchListSuccess = (message) => ({
    type: UPLOAD_CSV_TO_WATCHLIST_SUCCESS,
    payload: { message }
})

export const uploadCsvToWatchListFailed = (errorMessage) => ({
    type: UPLOAD_CSV_TO_WATCHLIST_FAILED,
    payload: { errorMessage }
})

import { RESTAPI_URL } from "../common/config";
import { commonFetch } from "./fetchComImpl";
import { refreshTokenApi } from "./refreshTokenApi";

export const callCreateWatchListApi = async (watchListObj) => {
    try{
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/watchList/saveWatchList`,
            'POST',
            watchListObj,
            new Headers({
                'Content-Type': 'application/json'
            })
        )
        return response;
    } catch(error){
        console.log('error inside watchListApi/callCreateWatchListApi ', error)
        throw error
    }
}

export const callUpdateWatchListApi = async (updateObj) => {
    try{
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/watchList/updateWatchList`,
            'POST',
            {updateObj},
            new Headers({
                'Content-Type': 'application/json'
            })
        )
        return response;
    } catch(error){
        console.log('error inside watchListApi/callUpdateWatchListApi', error)
        throw error
    }
}

export const callDeleteWatchListApi = async (watchListId) => {
    try{
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/watchList/deleteWatchList/${watchListId}`,
            'GET',
            {},
            new Headers({
                'Content-Type': 'application/json'
            })
        )
        return response;
    } catch(error){
        console.log('error inside watchListApi/callDeleteWatchListApi ', error)
        throw error
    }
}

export const callGetWatchListApi = async (userId) => {
    try{        
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/watchList/getWatchLists/${userId}`,
            'GET',
            {},
            new Headers({
                'Content-Type': 'application/json'
            })
        )
        return response;
    } catch(error){
        console.log('error inside watchListApi/callGetWatchListApi', error)
        throw error
    }
}

export const callAddAndRemovedCompanyToWatchListApi = async (watchList) => {
    try{
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/watchList/addAndRemovedCompanyToWatchList`,
            'POST',
            {watchList},
            new Headers({
                'Content-Type': 'application/json'
            })
        )
        return response;
    } catch(error){
        console.log('error inside watchListApi/callAddAndRemovedCompanyToWatchListApi', error)
        throw error
    }
}

export const callFindWatchListByRegApi = async (companyObj) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/watchList/findWatchListByReg`,
            'POST',
            {companyObj},
            new Headers({
                'Content-Type': 'application/json'
            })
        )
        return response;
    } catch(error){
        console.log('error inside watchListApi/callFindWatchListByRegApi', error)
    }
}

export const callUploadCsvToWatchListApi = async(formData) => {
    try {
        const response = await commonFetch(`${RESTAPI_URL}/watchList/uploadlist`,
            'PUT',
            formData,
            new Headers({})
        )
        return response;
    } catch(error){
        console.log('error inside watchListApi/callUploadCsvToWatchListApi ', error)
        throw error
    }
}
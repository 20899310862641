// import ReactPixel from 'react-facebook-pixel';
// import LinkedInTag from 'react-linkedin-insight';
// import { LINKEDIN_INSIGHT_ID, LINKEDIN_SIGNUP, LINKEDIN_SUBSCRIPTION, FACEBOOK_PIXEL_ID } from '../common/config';
import { GA_TRACKING_ID, GA_AD_SENSE_ID,MICROSOFT_CLARITY_ID } from '../common/config'
import { clarity } from 'react-microsoft-clarity';
// const GA_TRACKING_ID="UA-123021722-1"
let self = {
    onPageLoad: (pageName) => {
        // self.gtagPagePath(pageName)
        self.hubSpotSetPath(pageName)
        // self.socialMediaTracking(pageName)
    },
    onPageUnLoad: () => {
       
    },
    setCustomData: (key, value) => {
        if (clarity.hasStarted()) {
            clarity.setTag(key, value);
          }
    },
    clarityOnload: () =>{
        clarity.init(MICROSOFT_CLARITY_ID);
    },

 
    // Gtag api
    // gtagPagePath: (pageName) => {
        // self.gtag('config', GA_TRACKING_ID, { 'page_path': pageName });
    // },
    gtagInit: () => {
        self.gtag("js", new Date());
        // self.gtag("config", GA_TRACKING_ID);
        self.gtag("config", GA_AD_SENSE_ID);
    },
    gtag: function () {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(arguments);
    },
    gtag_report_conversion: (sendToId) => {
        let callback = () => { };
        self.gtag('event', 'conversion', {
            'send_to': sendToId,
            'event_callback': callback
        });
        return false;
    },

    //ApiHubspotApi

    hubSpotSetPath: (pageName) => {
        var _hsq = window._hsq = window._hsq || [];
        _hsq.push(['setPath', pageName]);
        _hsq.push(['trackPageView']);
    },

    socialMediaTracking: (pageName) => {
        // self.facebookTracking(pageName)
        // self.linkedinTracking(pageName)
    },
   

    // facebookTracking: (pageName) => {
    //     if (pageName === "/subscriptionPaymentSuccess" || pageName === "/" || pageName === "signupButtonClick" || pageName === "/login") {
    //         ReactPixel.init(FACEBOOK_PIXEL_ID);
    //         ReactPixel.trackCustom(pageName, {})
    //     }
    // },

    // linkedinTracking: (pageName) => {
    //     if (pageName === 'signupButtonClick') {
    //         LinkedInTag.init(LINKEDIN_INSIGHT_ID);
    //         LinkedInTag.track(LINKEDIN_SIGNUP);
    //     }
    //     if (pageName === '/subscriptionPaymentSuccess') {
    //         LinkedInTag.init(LINKEDIN_INSIGHT_ID);
    //         LinkedInTag.track(LINKEDIN_SUBSCRIPTION);
    //     }
    // }

}

export default self;
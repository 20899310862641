import { RESTAPI_URL } from '../common/config';
import { commonFetch } from './fetchComImpl'
import analyticsApi from './analyticsApi'
import { refreshTokenApi } from './refreshTokenApi';

export const callGetUserProfileApi = async () => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const userProfileResponse = await commonFetch(`${RESTAPI_URL}/user/getProfile`,
            'GET',
            {},
            new Headers({
                'content-type': 'application/json'
            }));
        if (userProfileResponse) {
            analyticsApi.setCustomData("userId", userProfileResponse.userProfile?._id);
            analyticsApi.setCustomData("userName", userProfileResponse.userProfile?.email);
        }
        return userProfileResponse;
    } catch (error) {
        throw error
    }
}

export const callSaveUserProfileApi = async (userInfo) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const saveUserProfileRes = await commonFetch(`${RESTAPI_URL}/user/saveProfile`,
            'POST',
            userInfo,
            new Headers({
                'content-type': 'application/json'
            }));
        return saveUserProfileRes;

    } catch (error) {
        throw error;
    }
}

export const callChangePasswordApi = async (oldPassword, newPassword) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const changePasswordResponse = await commonFetch(`${RESTAPI_URL}/user/changePassword`,
            'POST',
            { oldPassword, newPassword },
            new Headers({
                'Content-type': 'application/json'
            }));
        return changePasswordResponse;
    } catch (error) {
        throw error;
    }
}

export const callContactUsApi = async (userInfo) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/user/contactUs`,
            'POST',
            { userInfo },
            new Headers({
                'Content-Type': 'application/json'
            }));

        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}
export const submitInformationApi = async (userInfo) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/user/submitInformation`,
            'POST',
            { userInfo },
            new Headers({
                'Content-Type': 'application/json'
            }));

        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const callgetCreditReportApi = async (docId) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/user/getCreditReportUrl/${docId}`,
            'GET',
            {},
            new Headers({
                'Content-Type': 'application/json'
            }));
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}
export const callgetCreditReportPptApi = async (data) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/fileConverter/getPPT`,
            'POST',
            data,
            new Headers({
                'Content-Type': 'application/json'
            }));
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const callMakeDefaultPaymentMethodApi = async (cardData) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/user/makeDefaultPaymentMethod`,
            'POST',
            cardData,
            new Headers({
                'Content-Type': 'application/json'
            }));
        return response;
    } catch (error) {
        console.log("error while calling makeDefaultPaymentMethod", error);
        throw error;
    }
}


export const getSessionIdForAddNewCardApi = async (data) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/user/getSessionIdForAddNewCard`,
            'POST',
            data,
            new Headers({
                'Content-Type': 'application/json'
            }));
        return response;
    } catch (error) {
        console.log("error while calling getSessionIdForAddNewCardApi", error);
        throw error;
    }
}

export const saveCompanyHouseEmailNotificationApi = async (data) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/user/saveCompanyHouseEmailNotificationFlag`,
            'POST',
            data,
            new Headers({
                'Content-Type': 'application/json'
            }));
        return response;
    } catch (error) {
        console.log("error while calling saveCompanyHouseEmailNotificationApi", error);
        throw error;
    }
}

export const getUserOrderHistoryApi = async (data) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/user/getOrderHistory`,
            'POST',
            data,
            new Headers({
                'Content-Type': 'application/json'
            }));
        return response;
    } catch (error) {
        console.log('inside getUserOrderHistoryApi ', error);
        throw error;
    }
}

export const getValuationReportsListApi=async(data)=>{
    try {
        const response=await commonFetch(`${RESTAPI_URL}/user/getValuationReportsList`,
        'POST',
        data,
        new Headers({
            'Content-Type':'application/json'
        }));
        return response;
    } catch (error) {
        console.log('inside getValuationReportsListApi ',error);
        throw error;
    }
}

export const getValuationReportPptRequestApi=async(data)=>{
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
        'POST',
        {},
        new Headers({
            'content-type': 'application/json'
        }));
        const response = await commonFetch(`${RESTAPI_URL}/fileConverter/getPPT`,
        'POST',
        data,
        new Headers({
            'Content-Type': 'application/json'
        }));
        return response;
    } catch (error) {
        console.log('inside getValuationReportPptRequestApi ',error);
        throw error;
    }
}

export const getBusinessPlanReportsPptRequestApi=async(data)=>{
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
        'POST',
        {},
        new Headers({
            'content-type': 'application/json'
        }));
        const response = await commonFetch(`${RESTAPI_URL}/fileConverter/getPPT`,
        'POST',
        data,
        new Headers({
            'Content-Type': 'application/json'
        }));
        return response;
    } catch (error) {
        console.log('inside getBusinessPlanReportsPptRequestApi ',error);
        throw error;
    }
}

export const getBusinessPlansListApi=async(data)=>{
    try {
        const response=await commonFetch(`${RESTAPI_URL}/user/getBusinessPlanList`,
        'POST',
        data,
        new Headers({
            'Content-Type':'application/json'
        }));
        return response;
    } catch (error) {
        console.log('inside getBusinessPlansListApi ',error);
        throw error;
    }
}

export const saveWhiteLabelInfoApi = async (whiteLabelInfo) => {
    try {

        let formData = new FormData();
        formData.append('companyName', whiteLabelInfo.companyName);
        formData.append('primaryColor', whiteLabelInfo.primaryColor);
        formData.append('secondaryColor', whiteLabelInfo.secondaryColor);
        formData.append('pageHeadingTextColor', whiteLabelInfo.pageHeadingTextColor);
        
        if(whiteLabelInfo.file){
            const fileObj = dataURIToBlob(whiteLabelInfo.file,whiteLabelInfo.fileName);
            formData.append('image', fileObj);
        }
        const saveWhiteLabelInfoRes = await commonFetch(`${RESTAPI_URL}/user/saveVRWhiteLabelInfo`,
            'PUT',
            formData,
            new Headers({}));
            
        return saveWhiteLabelInfoRes;

    } catch (error) {
        throw error;
    }
}

const  dataURIToBlob=(dataURI,filename) =>{
    const arr = dataURI.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]); 
    let n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}

export const getWhiteLabelInfoApi=async(userId)=>{
    try {
        const response=await commonFetch(`${RESTAPI_URL}/user/getVRWhiteLabelInfo/${userId}`,
        'GET',
        {},
        new Headers({
            'Content-Type':'application/json'
        }));
        return response;
    } catch (error) {
        throw error;
    }
}

export const resetWhiteLabelInfoApi=async(userId)=>{
    try {
        const response=await commonFetch(`${RESTAPI_URL}/user/resetVRWhiteLabelInfo`,
        'POST',
        {userId},
        new Headers({
            'Content-Type':'application/json'
        }));
        return response;
    } catch (error) {
        throw error;
    }
}

export const updateBusinessPlansCreditApi = async () => {
    try {
        const response = await commonFetch(`${RESTAPI_URL}/user/updateUserBusinessPlanCredits`,
            'GET',
            {},
            new Headers({
                'content-type': 'application/json'
            }));
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const regeneratePdfApi = async (data) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/user/regenerateCrPdf`,
            'POST',
            data,
            new Headers({
                'Content-Type': 'application/json'
            }));
        return response;
    } catch (error) {
        console.log('inside regeneratePdfApi ', error);
        throw error;
    }
}

export const regenerateCpPdfApi = async (data) => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/user/regenerateCpPdf`,
            'POST',
            data,
            new Headers({
                'Content-Type': 'application/json'
            }));
        return response;
    } catch (error) {
        console.log('inside regenerateCpPdfApi ', error);
        throw error;
    }
}
import React from 'react'
import './PageNotFound.css'

function PageNotFound(props) {
    return (
        <div className='page-not-found-wrapper'>
            <a href="/" target='_self'>
                <picture>
                    <source srcSet='/assets/images/pomanda_yellow_black.webp' />
                    <img src='/assets/images/pomanda_yellow_black.png' width={300} height={75}></img>
                </picture>
            </a>
            <div className='mt-3'>
                <p><b>404.</b> <ins>That’s an error.</ins>
                </p>
                <p>The requested URL <code>{props.path}</code> was not found on this server.  <ins>That’s all we know.</ins>
                </p>
                <p>
                Go back to home <a href="/" target='_self'><u>click here</u></a>
                </p>
            </div>
        </div>
    )
}

export default PageNotFound
import { all, call, put, takeLatest } from "redux-saga/effects";
import { ADD_COMPANY_TO_WATCHLIST_REQUEST, COMPANY_WATCHLIST_INFO_REQUEST, CREATE_WATCHLIST_REQUEST, DELETE_WATCHLIST_REQUEST, GET_WATCHLIST_REQUEST, REMOVE_COMPANY_FROM_WATCHLIST_REQUEST, UPDATE_WATCHLIST_REQUEST, UPLOAD_CSV_TO_WATCHLIST_REQUEST } from "../redux/actions/actionConstants";
import { addCompanyToWatchListFailed, createWatchListFailed, deleteWatchListFailed, findCompanyInWatchListRequest, findCompanyInWatchListSuccess, getUpdateWatchListAfterSuccess, getWatchListFailed, removeCompanyFromWatchListFailed, removeCompanyFromWatchListSuccess, updateWatchListFailed, uploadCsvToWatchListFailed, uploadCsvToWatchListSuccess } from "../redux/actions/watchListAction";
import { callAddAndRemovedCompanyToWatchListApi, callCreateWatchListApi, callDeleteWatchListApi, callFindWatchListByRegApi, callGetWatchListApi, callUpdateWatchListApi, callUploadCsvToWatchListApi } from "../api/watchListApi";
import { setLoading } from "../redux/actions/commonAction";

function* createWatchList(action) {
    try {
        const apiResponse = yield call(callCreateWatchListApi, action.payload.watchListObj);
        if (!apiResponse.error) {
            yield put(getUpdateWatchListAfterSuccess(apiResponse.watchList))
        } else {
            yield put(createWatchListFailed(apiResponse.message))
        }
    } catch (error) {
        console.log('error inside watchListSaga/createWatchList ', error)
        yield put(createWatchListFailed(error))
    }
}

export function* watchCreateWatchListSage() {
    yield takeLatest(CREATE_WATCHLIST_REQUEST, createWatchList)
}

function* updateWatchList(action) {
    try {
        const apiResponse = yield call(callUpdateWatchListApi, action.payload.updateObj);
        if (!apiResponse.error) {
            yield put(getUpdateWatchListAfterSuccess(apiResponse.watchList))
        } else {
            yield put(updateWatchListFailed(apiResponse.message))
        }
    } catch (error) {
        console.log('error inside watchListSaga/updateWatchList ', error)
        yield put(updateWatchListFailed(error))
    }
}

export function* watchUpdateWatchListSaga() {
    yield takeLatest(UPDATE_WATCHLIST_REQUEST, updateWatchList)
}

function* deleteWatchList(action) {
    try {
        const apiResponse = yield call(callDeleteWatchListApi, action.payload.watchListId);
        if (!apiResponse.error) {
            yield put(getUpdateWatchListAfterSuccess(apiResponse.watchList))
        } else {
            yield put(deleteWatchListFailed(apiResponse.message))
        }
    } catch (error) {
        console.log('error inside watchListSaga/deleteWatchList ', error)
        yield put(deleteWatchListFailed(error))
    }
}
export function* watchDeleteWatchListSage() {
    yield takeLatest(DELETE_WATCHLIST_REQUEST, deleteWatchList)
}

function* getWatchList(action) {
    try {
        const apiResponse = yield call(callGetWatchListApi, action.payload.userId);
        if (!apiResponse.error) {
            yield put(getUpdateWatchListAfterSuccess(apiResponse.watchList))
        } else {
            yield put(getWatchListFailed(apiResponse.message))
        }
    } catch (error) {
        console.log('error inside watchListSaga/getWatchList ', error)
        yield put(getWatchListFailed(error))
    }
}
export function* watchGetWatchListSaga() {
    yield takeLatest(GET_WATCHLIST_REQUEST, getWatchList)
}

function* addCompanyToWatchList(action) {
    try {
        const { updatedWatchList, isRegWatchListApiRequired } = action.payload.watchListObj
        if (updatedWatchList[0].companyQueryData) {
            yield put(setLoading({ status: true, timeout: 7000 }));
        }
        const apiResponse = yield call(callAddAndRemovedCompanyToWatchListApi, updatedWatchList)
        if (!apiResponse.error) {
            const { list: [{ REG }], userId } = updatedWatchList[0];
            if (isRegWatchListApiRequired) {
                yield put(findCompanyInWatchListRequest({ REG, userId }))
            }
            yield put(getUpdateWatchListAfterSuccess(apiResponse.watchList))
        } else {
            yield put(addCompanyToWatchListFailed(apiResponse.error))
        }
        yield put(setLoading({ status: false }))
    } catch (error) {
        console.log('error inside watchListSaga/addCompanyToWatchList ', error)
        yield all([
            put(setLoading({ status: false })),
            put(addCompanyToWatchListFailed(error))
        ])
    }
}
export function* watchAddCompanyToWatchList() {
    yield takeLatest(ADD_COMPANY_TO_WATCHLIST_REQUEST, addCompanyToWatchList)
}

function* removeCompanyFromWatchList(action) {
    try {
        const apiResponse = yield call(callAddAndRemovedCompanyToWatchListApi, action.payload.companyObj)
        if (!apiResponse.error) {
            yield put(removeCompanyFromWatchListSuccess(apiResponse.message))
        } else {
            yield put(removeCompanyFromWatchListFailed(apiResponse.error))
        }
    } catch (error) {
        console.log('error inside watchListSaga/removeCompanyFromWatchList ', error)
        yield put(removeCompanyFromWatchListFailed(error))
    }
}
export function* watchRemoveCompanyToWatchList() {
    yield takeLatest(REMOVE_COMPANY_FROM_WATCHLIST_REQUEST, removeCompanyFromWatchList)
}

function* findCompanyInWatchListSaga(action) {
    try {
        const apiResponse = yield call(callFindWatchListByRegApi, action.payload.companyObj)
        if (!apiResponse.error) {
            yield put(findCompanyInWatchListSuccess(apiResponse?.watchListData))
        } else {
            yield put(removeCompanyFromWatchListFailed(error))
        }
    } catch (error) {
        console.log('error inside watchListSaga/findCompanyInWatchListSaga ', error)
        yield put(removeCompanyFromWatchListFailed(error))
    }
}

export function* watchFindCompanyInWatchListSaga() {
    yield takeLatest(COMPANY_WATCHLIST_INFO_REQUEST, findCompanyInWatchListSaga)
}

function* uploadCsvToWatchList(action) {
    try {
        yield put(setLoading({ status: true, timeout: 7000 }));
        const apiResponse = yield call(callUploadCsvToWatchListApi, action.payload.formData)
        if (!apiResponse.error) {
            yield all([
                put(setLoading({ status: false })),
                put(getUpdateWatchListAfterSuccess(apiResponse.updatedWatchList)),
            ]);
            window.alert(apiResponse.message);
        } else {
            if (apiResponse.status === 400) {
                window.alert(apiResponse.message)
            }
            yield all([
                put(setLoading({ status: false })),
                put(uploadCsvToWatchListFailed(apiResponse.error))
            ])
        }
    } catch (error) {
        console.log('error inside watchListSage/uploadCsvToWatchList', error)
        yield all([
            put(setLoading({ status: false })),
            put(uploadCsvToWatchListFailed(error))
        ])
    }
}

export function* watchUploadCsvToWatchList() {
    yield takeLatest(UPLOAD_CSV_TO_WATCHLIST_REQUEST, uploadCsvToWatchList)
}
import {
    TOGGLE_SEARCH_ESTIMATION,
    TOGGLE_SEARCH_FILTER,
    SET_COMPANY_SEARCH_APPLIED_FILTER,
    TOGGLE_SEARCH_LIST_VIEW,
    SET_FILTER_OBJECT_LIST,
    SHOW_ESTIMATION_TABLET,
    SHOW_SEARCH_OPTIONS_TABLET,
    GET_COMPANY_SEARCH_RESULTS,
    SEARCH_COMPANY_API,
    SEARCH_COMPANY_API_SUCCESS,
    SEARCH_COMPANY_API_FAILED,
    RESET_COMPANY_SEARCH_LIST,
    SET_POWER_SEARCH_TYPE_REQUEST,
    SET_COMPANY_LIST_VIEW_SORTING,
    GET_DIRECTOR_LIST_REQUEST,
    GET_DIRECTOR_LIST_SUCCESS,
    GET_DIRECTOR_LIST_FAILED,
    RESET_DIRECTOR_SEARCH_LIST,
    SET_COMPANY_SEARCH_PARAMS,
    SET_DIRECTOR_SEARCH_PARAMS,
    SET_DIRECTOR_SEARCH_APPLIED_FILTER,
    GET_INDUSTRY_FILTER_DATA_REQUEST,
    SET_INDUSTRY_FILTER_DATA,
    GET_INDUSTRY_FILTER_DATA_FAILED,
    SET_ACTIVITY_TRACKING_DATA,
    CALL_SINGLE_SEARCH_API,
    CALL_SINGLE_SEARCH_API_ERROR,
    SET_TRY_POWER_SEARCH,
    SET_TREE_FILTER_QUERY_PARAMS,
    SET_SAVED_SEARCHES,
    SET_ADD_CURRENT_SEARCH,
    GET_SAVE_SEARCHES,
    SAVE_CURRENT_SEARCH,
    SET_SAVED_SEARCHES_LIST,
    DELETE_SAVED_SEARCH,
    DOWNLOAD_RESULTS_REQUEST,
    INCREASE_DOWNLOAD_RESULT_LIMIT_REQUEST,
    SET_SAVE_CURRENT_SEARCH_ERROR,
    SET_SEARCH_ESTIMATION,
    SET_RESET_FILTER_TYPE,
    SET_APPLIED_LIST_MODAL_TOGGLE_STATUS,
    SET_APPLIED_RESET_MODAL_TOGGLE_STATUS,
    TOGGLE_ESTIMATE_WARNING,
    SET_INDUSTRY_SEARCH_PARAMS,
    GET_INDUSTRY_LIST_REQUEST,
    SET_SHAREHOLDER_SEARCH_PARAMS,
    GET_SHAREHOLDER_LIST_REQUEST,
    GET_INDUSTRY_LIST_SUCCESS,
    GET_SHAREHOLDER_LIST_SUCCESS,
    SET_INDUSTRY_SEARCH_APPLIED_FILTER,
    RESET_INDUSTRY_SEARCH_LIST,
    RESET_SHAREHOLDER_SEARCH_LIST,
    SET_SHAREHOLDER_SEARCH_APPLIED_FILTER,
    SET_CHARGES_SEARCH_APPLIED_FILTER,
    RESET_CHARGES_SEARCH_LIST,
    SET_CHARGES_SEARCH_PARAMS,
    GET_CHARGES_LIST_REQUEST,
    GET_CHARGES_LIST_SUCCESS,
    RESET_ALL_SEARCH_PARAMS,
    SET_REGION_SEARCH_APPLIED_FILTER,
    RESET_REGION_SEARCH_LIST,
    SET_REGION_SEARCH_PARAMS,
    GET_REGION_LIST_REQUEST,
    GET_REGION_LIST_SUCCESS,
    SET_FILTER_RESTRICTION_MODAL,
    SET_DOWNLOAD_SEARCH_RESULTS_BTN_STATUS,
    SET_POWER_SEARCH_SHARE,
    TOGGLE_FEATURE_LOCK_STATUS,
    SET_WATCHING_FILTER_QUERY_PARAMS,
} from './actionConstants';

export const toggleSearchEstimation = () => ({
    type: TOGGLE_SEARCH_ESTIMATION,
})

export const setSearchEstimation = (estToggleStatus) => ({
    type: SET_SEARCH_ESTIMATION,
    payload: { estToggleStatus }
})

export const toggleSearchFilter = (filterToggleState) => ({
    type: TOGGLE_SEARCH_FILTER,
    payload: { filterToggleState }
})

export const toggleSearchListView = () => ({
    type: TOGGLE_SEARCH_LIST_VIEW,
})

export const setCompanySearchAppliedFilter = (appliedFilter=null) => ({
    type: SET_COMPANY_SEARCH_APPLIED_FILTER,
    payload: appliedFilter
})

export const setDirectorSearchAppliedFilter = (appliedFilter=null) => ({
    type: SET_DIRECTOR_SEARCH_APPLIED_FILTER,
    payload: appliedFilter
})

export const setIndustrySearchAppliedFilter = (appliedFilter=null) => ({
    type: SET_INDUSTRY_SEARCH_APPLIED_FILTER,
    payload: appliedFilter
})

export const setShareholderSearchAppliedFilter = (appliedFilter=null) => ({
    type: SET_SHAREHOLDER_SEARCH_APPLIED_FILTER,
    payload: appliedFilter
})

export const setChargesSearchAppliedFilter = (appliedFilter=null) => ({
    type: SET_CHARGES_SEARCH_APPLIED_FILTER,
    payload: appliedFilter
})

export const setRegionSearchAppliedFilter = (appliedFilter=null) => ({
    type: SET_REGION_SEARCH_APPLIED_FILTER,
    payload: {appliedFilter}
})

export const setFilterObjectList = (FilterList) => ({
    type: SET_FILTER_OBJECT_LIST,
    payload: FilterList
})

export const showEstimationTablet = (showEstimation) => ({
    type: SHOW_ESTIMATION_TABLET,
    payload: showEstimation
})

export const showSearchOptionsTablet = (optionsStatus) => ({
    type: SHOW_SEARCH_OPTIONS_TABLET,
    payload: optionsStatus
})
export const callCompanySearchApi = (companyQueryData) => ({
    type: SEARCH_COMPANY_API,
    payload: companyQueryData
})
export const getCompanySearchResults = (companyQueryData) => ({
    type: GET_COMPANY_SEARCH_RESULTS,
    payload: companyQueryData
})
export const searchCompanyApiSuccess = (companySearchResp) => ({
    type: SEARCH_COMPANY_API_SUCCESS,
    payload: {companySearchResp}
})
export const searchCompanyApiFailed = (error) => ({
    type: SEARCH_COMPANY_API_FAILED,
    payload: error
})
export const getDirectorListRequest = (directorQueryData) => ({
    type: GET_DIRECTOR_LIST_REQUEST,
    payload: {directorQueryData}
})
export const getDirectorListSuccess = (directorSearchResp) => ({
    type: GET_DIRECTOR_LIST_SUCCESS,
    payload: {directorSearchResp}
})
export const getDirectorListFailed = (error) => ({
    type: GET_DIRECTOR_LIST_FAILED,
    payload: error
})
export const resetCompanySearchList = () => ({
    type: RESET_COMPANY_SEARCH_LIST,
})
export const resetDirectorSearchList = () => ({
    type: RESET_DIRECTOR_SEARCH_LIST,
})
export const resetIndustrySearchList = () => ({
    type: RESET_INDUSTRY_SEARCH_LIST,
})
export const resetShareholderSearchList = () => ({
    type: RESET_SHAREHOLDER_SEARCH_LIST,
})
export const resetChargesSearchList = () => ({
    type: RESET_CHARGES_SEARCH_LIST,
})
export const resetRegionSearchList = () => ({
    type: RESET_REGION_SEARCH_LIST,
})
export const setPowerSearchType = (type) => ({
    type: SET_POWER_SEARCH_TYPE_REQUEST,
    payload: {type}
})
export const setCompanyListViewSorting = (sortField, sortType) => ({
    type: SET_COMPANY_LIST_VIEW_SORTING,
    payload: { sortField, sortType }
})
export const setCompanySearchParams = (companyQueryData) => ({
    type: SET_COMPANY_SEARCH_PARAMS,
    payload: {companyQueryData}
})
export const setDirectorSearchParams = (directorQueryData) => ({
    type: SET_DIRECTOR_SEARCH_PARAMS,
    payload: {directorQueryData}
})
export const setIndustrySearchParams = (industryQueryData) => ({
    type: SET_INDUSTRY_SEARCH_PARAMS,
    payload: {industryQueryData}
})
export const getIndustryListRequest = (industryQueryData) => ({
    type: GET_INDUSTRY_LIST_REQUEST,
    payload: {industryQueryData}
})
export const setShareholderSearchParams = (shareholderQueryData) => ({
    type: SET_SHAREHOLDER_SEARCH_PARAMS,
    payload: {shareholderQueryData}
})
export const getShareholderListRequest = (shareholderQueryData) => ({
    type: GET_SHAREHOLDER_LIST_REQUEST,
    payload: {shareholderQueryData}
})
export const setChargesSearchParams = (chargesQueryData) => ({
    type: SET_CHARGES_SEARCH_PARAMS,
    payload: {chargesQueryData}
})
export const getChargesListRequest = (chargesQueryData) => {
    return ({
        type: GET_CHARGES_LIST_REQUEST,
        payload: {chargesQueryData}
    })
}
export const setRegionSearchParams = (regionQueryData) => ({
    type: SET_REGION_SEARCH_PARAMS,
    payload: {regionQueryData}
})
export const getRegionListRequest = (regionQueryData) => {
    return ({
        type: GET_REGION_LIST_REQUEST,
        payload: {regionQueryData}
    })   
}
export const getIndustryListSuccess = (industrySearchResp) => ({
    type: GET_INDUSTRY_LIST_SUCCESS,
    payload: {industrySearchResp}
})
export const getShareholderListSuccess = (shareholderSearchResp) => ({
    type: GET_SHAREHOLDER_LIST_SUCCESS,
    payload: {shareholderSearchResp}
})
export const getChargesListSuccess = (chargesSearchResp) => ({
    type: GET_CHARGES_LIST_SUCCESS,
    payload: {chargesSearchResp}
})
export const getRegionListSuccess = (regionSearchResp) => ({
    type: GET_REGION_LIST_SUCCESS,
    payload: {regionSearchResp}
})
export const getIndustryFilterDataRequest = () => ({
    type: GET_INDUSTRY_FILTER_DATA_REQUEST,
})
export const setIndustryFilterData = (onloadFilterData) => ({
    type: SET_INDUSTRY_FILTER_DATA,
    payload: {onloadFilterData}
})
export const getIndustryFilterDataFailed = (error) => ({
    type: GET_INDUSTRY_FILTER_DATA_FAILED,
    payload: error
})
export const setActivityTrackingData = (trackingObj) => ({
    type: SET_ACTIVITY_TRACKING_DATA,
    payload: {trackingObj}
})
export const setTreeFilterQueryParams = (treeQueryParams) => ({
    type: SET_TREE_FILTER_QUERY_PARAMS,
    payload: {treeQueryParams}
})
export const setPowerSearchShare = (powerSearchShareObj) => ({
    type: SET_POWER_SEARCH_SHARE,
    payload: {powerSearchShareObj}
})
export const setWatchingFilterQueryParams = (filterObj) => ({
    type: SET_WATCHING_FILTER_QUERY_PARAMS,
    payload: {filterObj}
})

export const callSingleSearchApiAction = (searchObj) => ({
    type: CALL_SINGLE_SEARCH_API,
    payload: {searchObj}
})


export const callSingleSearchApiErrorAction = (errorObj) => ({
    type: CALL_SINGLE_SEARCH_API_ERROR,
    payload: {errorObj}
})

export const setTryPowerSearch = (tryStatus,tryPowerSearchData) => ({
    type: SET_TRY_POWER_SEARCH,
    payload: {tryStatus,tryPowerSearchData}
})

export const setSavedSearches=(status)=>({
    type:SET_SAVED_SEARCHES,
    payload:{status}
})

export const setAddCurrentSearch=(status)=>({
    type:SET_ADD_CURRENT_SEARCH,
    payload:{status}
})

export const getSaveSearches=(searchObj)=>({
    type:GET_SAVE_SEARCHES,
    payload:{searchObj}
})

export const saveCurrentSearch=(searchObj)=>({
    type:SAVE_CURRENT_SEARCH,
    payload:{searchObj}
})

export const setSavedSearchesList=(savedSearches)=>({
    type:SET_SAVED_SEARCHES_LIST,
    payload:{savedSearches}
})

export const setSaveCurrentSearchError=(errorMsg)=>({
    type:SET_SAVE_CURRENT_SEARCH_ERROR,
    payload:{errorMsg}
})

export const deleteSavedSearch=(searchId)=>({
    type:DELETE_SAVED_SEARCH,
    payload:{searchId}
})

export const downloadResults = (searchQueryData) => ({
    type: DOWNLOAD_RESULTS_REQUEST,
    payload: { searchQueryData }
});

export const increaseDownloadResultLimitRequest = (requestDetails) => ({
    type: INCREASE_DOWNLOAD_RESULT_LIMIT_REQUEST,
    payload: { requestDetails }
});

export const setResetFilterType = (resetFilterType) => ({
    type: SET_RESET_FILTER_TYPE,
    payload: { resetFilterType }
});

export const setListModalToggleStatus = (toggleStatus) => ({
    type: SET_APPLIED_LIST_MODAL_TOGGLE_STATUS,
    payload: { toggleStatus }
});

export const setResetModalToggleStatus = (toggleStatus) => ({
    type: SET_APPLIED_RESET_MODAL_TOGGLE_STATUS,
    payload: { toggleStatus }
});

export const toggleEstimateWarning = (toggleStatus) => ({
    type: TOGGLE_ESTIMATE_WARNING,
    payload: { toggleStatus }
});

export const resetAllSearchParams = (searchParam)=>({
    type: RESET_ALL_SEARCH_PARAMS,
    payload: { searchParam }
})

export const setFilterRestrictionModal = (status, modalInfo)=>({
    type: SET_FILTER_RESTRICTION_MODAL,
    payload: { status, modalInfo }
})

export const setDownloadSearchResultsBtnStatus = (status) => ({
    type: SET_DOWNLOAD_SEARCH_RESULTS_BTN_STATUS,
    payload: { status }
})

export const toggleFeatureLockStatus = (status) => ({
    type: TOGGLE_FEATURE_LOCK_STATUS,
    payload: {status}
})